<template>
  <div class="stats-page">
    <div class="stats-wrapper">
      <div class="stats-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style b-form-select-new-style-w-1"
            v-model="year"
            :options="years"
            @change="onYearChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  w-63-px"
            v-model="month"
            :options="[
              { value: null, text: 'Tous les mois' },
              ...computedMonths
            ]"
            @change="onMonthChange"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  w-63-px"
            v-model="week"
            :options="[
              { value: null, text: 'Tous les semaines' },
              ...getcomputedListWeek(month)
            ]"
            text-field="text"
            value-field="value"
            @change="onWeekChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  w-63-px"
            v-model="filiale"
            :options="[
              { value: null, text: 'Tous les installateurs' },
              ...getcomputedFiliale(month, week)
            ]"
            text-field="text"
            value-field="value"
            @change="onFilialeChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Dépôt</span>
          </div>
          <b-form-select
            class="b-form-select-new-style "
            v-model="depot"
            :options="[
              { value: null, text: 'Tous les dépots' },
              ...getcomputedDepots(month, week, filiale)
            ]"
            text-field="text"
            value-field="value"
            @change="onDepotChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="computedRoleSupport">
          <div class="label-box-style  w-78-px">
            <span class="title-tabel">Filiale Support</span>
          </div>

          <b-form-select
            class="b-form-select-new-style  w-78-px"
            v-model="support"
            :options="computedListType"
            text-field="text"
            value-field="value"
            @change="onSupportChange"
          ></b-form-select>
        </div>
      </div>

      <div class="stats-body">
        <div class="stats-content">
          <div class="stats-content-row">
            <div class="stats-content-col mini-col">
              <div class="total-stats">
                <div class="left-stats">
                  <div class="total-stats-title">
                    Dossier injecté dans CDG
                  </div>
                  <div class="total-stats-value">
                    <ICountUp
                      :delay="delay"
                      :endVal="getStatsTotalProject ? getStatsTotalProject : 0"
                      @ready="onReadyDossier"
                    />
                  </div>
                </div>
                <div class="right-stats">
                  <img
                    src="../assets/folder.svg"
                    class="icon-upload"
                    width="64"
                  />
                </div>
              </div>
            </div>
            <div class="stats-content-col mini-col">
              <div class="total-stats">
                <div class="left-stats">
                  <div class="total-stats-title">
                    Nombre de factures générées <br />par les filliales support
                  </div>
                  <div class="total-stats-value">
                    <ICountUp
                      :delay="delay"
                      :endVal="
                        getStatsNombreFactureTotale
                          ? parseInt(
                              getStatsNombreFactureTotale.replace(/\s/g, '')
                            )
                          : 0
                      "
                      :options="optionsNbr"
                      @ready="onReadyNombre"
                    />
                  </div>
                </div>
                <div class="right-stats">
                  <img
                    src="../assets/invoice.svg"
                    class="icon-upload"
                    width="64"
                  />
                </div>
              </div>
            </div>
            <div class="stats-content-col mini-col">
              <div class="total-stats">
                <div class="left-stats">
                  <div class="total-stats-title">
                    Montant de Factures générées <br />par les filliales support
                  </div>
                  <div class="total-stats-value">
                    <ICountUp
                      :delay="delay"
                      :endVal="
                        getStatsSommeFactureTotale
                          ? parseFloat(
                              getStatsSommeFactureTotale.replace(/\s/g, '')
                            )
                          : 0
                      "
                      :options="optionsMontant"
                      @ready="onReadyMontant"
                    />
                  </div>
                </div>
                <div class="right-stats">
                  <img
                    src="../assets/euro.svg"
                    class="icon-upload"
                    width="64"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="stats-row-panel">
            <div class="child--1">
              <div class="chart-title">
                <span class="text">
                  Rapport dossier par Dépôt
                </span>
                <b-form-checkbox
                  @change="updateChartDepot"
                  name="check-button"
                  switch
                  size="sm"
                  class="switchDashbord"
                >
                  <div v-if="!currentStateDepot" class="btnchart">
                    Par dossier
                  </div>
                  <div v-if="currentStateDepot" class="btnchart">
                    Par montant
                  </div>
                </b-form-checkbox>
              </div>
              <div class="maps-form-style">
                <div class="data-style">
                  <div
                    v-if="pointedLocation"
                    class="examples__block__map__tooltip"
                    :style="leftStyle + '; ' + topStyle"
                  >
                    {{ pointedLocation }}
                  </div>
                </div>
                <div class="stats-content-col maps">
                  <b-form-group class="vue-select-modal-champ ">
                    <multiselect
                      v-model="departmentsListe"
                      :options="getAllFranceDepartement"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :preselect-first="true"
                      required
                      class="select-vue-component-style dep"
                      :showLabels="false"
                      @input="AddLocation"
                      placeholder="Sélectionner Dépôt"
                    >
                    </multiselect>
                  </b-form-group>

                  <checkbox-svg-map
                    v-model="selectedLocations"
                    :map="FranceDepartement"
                    @mouseover="pointLocation"
                    @mouseout="unpointLocation"
                    @focus="focusLocation"
                    @blur="blurLocation"
                    @change="handleGetDepartementSVG"
                  />
                </div>
                <div class="map-chart">
                  <div v-if="getLoadingPerMap" class="chargement">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div v-else>
                    <div v-show="!currentStateDepot">
                      <apexchart
                        width="250"
                        type="donut"
                        :options="computedChartMontantDepotOptions"
                        :series="
                          seriesChartMontantDepotSvg.length <= 0
                            ? computedseriesChartMontantDepot
                            : computedseriesChartMontantDepotSvg
                        "
                      ></apexchart>
                    </div>
                    <div v-show="currentStateDepot">
                      <apexchart
                        width="250"
                        type="donut"
                        :options="computedChartDepotOptions"
                        :series="
                          chartDepartementNbrDossierSeriesSvg.length <= 0
                            ? computedseriesChartDepot
                            : computedseriesChartDepotSVG
                        "
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-rapport-style w-100-p">
                <b-table
                  show-empty
                  id="my-table"
                  class="custom-table-style customTableGestionRegieAdmin table-header"
                  :items="tableDepartementItems"
                  :fields="tableDepartementFields"
                  bordered
                  sticky-header
                  hover
                  responsive
                  head-variant="light"
                  empty-text="Il n'y a aucun enregistrement à afficher"
                  :busy="getLoadingPerMap"
                >
                  <template #table-busy>
                    <div class="text-center text-dark my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>

            <div class="stats-content-col child--2">
              <div class="chart-title">
                <span class="text">
                  Rapport factures par année et mois
                </span>
                <div v-if="getStatsPerMonthLoading" class="chargement">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <apexchart
                type="bar"
                height="350"
                width="100%"
                :options="computedChartParMoisNombreOptions"
                :series="computedChartParMoisNombreSeries"
              ></apexchart>

              <apexchart
                type="bar"
                height="350"
                width="100%"
                :options="computedChartParMoisMontantOptions"
                :series="computedChartParMoisMontantSeries"
              ></apexchart>
            </div>
          </div>

          <div class="child--100">
            <div class="chart-title">
              <span class="text">
                Rapport société par support
              </span>
              <div v-if="getStatsLoading" class="chargement">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <b-form-checkbox
                @change="updateChart"
                name="check-button"
                switch
                size="sm"
                class="switchDashbord"
              >
                <div v-if="currentState" class="btnchart">
                  Par dossier
                </div>
                <div v-if="!currentState" class="btnchart">
                  Par montant
                </div>
              </b-form-checkbox>
            </div>

            <apexchart
              type="bar"
              height="250"
              width="100%"
              :options="
                this.currentState
                  ? computedChartInstallateurMontantOptions
                  : computedChartInstallateurNombreOptions
              "
              :series="
                this.currentState
                  ? computedChartInstallateurMontantSeries
                  : computedChartInstallateurNombreSeries
              "
            ></apexchart>
            <div class="table-rapport-style w-100-p">
              <b-table
                show-empty
                id="my-table"
                class="custom-table-style customTableGestionRegieAdmin table-header"
                :items="tableSupportItems"
                :fields="tableSupportFields"
                bordered
                sticky-header
                hover
                responsive
                head-variant="light"
                empty-text="Il n'y a aucun enregistrement à afficher"
                :busy="getStatsLoading"
              >
                <template #table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>

          <div class="child--100">
            <div class="chart-title">
              <span class="text">
                Rapport factures générées par statut et par installateur
              </span>
              <div v-if="getStatsPerFilialeLoading" class="chargement">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <b-form-checkbox
                @change="updateChartFacture"
                name="check-button"
                switch
                size="sm"
                class="switchDashbord"
              >
                <div v-if="currentStateFacture" class="btnchart">
                  Par dossier
                </div>
                <div v-if="!currentStateFacture" class="btnchart">
                  Par montant
                </div>
              </b-form-checkbox>
            </div>
            <apexchart
              type="bar"
              height="350"
              width="100%"
              :options="
                this.currentStateFacture
                  ? computedChartFilialeMontantOptions
                  : computedChartFilialeOptions
              "
              :series="
                this.currentStateFacture
                  ? computedChartFilialeMontantSeries
                  : computedChartFilialeSeries
              "
            ></apexchart>

            <div class="table-rapport-style w-100-p">
              <b-table
                show-empty
                id="my-table"
                class="custom-table-style customTableGestionRegieAdmin table-header"
                :items="tableFilialeItems"
                :fields="tableFilialeFields"
                bordered
                sticky-header
                hover
                responsive
                head-variant="light"
                empty-text="Il n'y a aucun enregistrement à afficher"
                :busy="getStatsPerFilialeLoading"
              >
                <template #table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { CheckboxSvgMap } from 'vue-svg-map';
import FranceDepartement from '@svg-maps/france.departments';
import ICountUp from 'vue-countup-v2';
export default {
  name: 'StatisticsGeneral',
  components: {
    apexchart: VueApexCharts,
    CheckboxSvgMap: CheckboxSvgMap,
    ICountUp
  },
  data: function() {
    return {
      years: [],
      year: null,
      month: null,
      week: null,
      filiale: null,
      depot: null,
      chartParMoisNombreSeries: [],
      chartParMoisNombreCategories: [],
      chartParMapCategories: [],
      chartParMoisMontantSeries: [],
      chartParMoisMontantCategories: [],
      chartInstallateurNombreSeries: [],
      chartInstallateurNombreCategories: [],
      chartInstallateurMontantSeries: [],
      chartInstallateurMontantCategories: [],
      chartFilialeSeries: [],
      chartFilialeCategories: [],
      tableFilialeItems: [],
      tableSupportItems: [],
      tableFilialeFields: [
        {
          key: 'name',
          label: 'Installateur'
        },
        {
          key: 'paye',
          label: 'Payées'
        },
        {
          key: 'paye_montant',
          label: 'Montant Payées'
        },
        {
          key: 'a_paye',
          label: 'À payer'
        },
        {
          key: 'a_paye_montant',
          label: 'Montant À payer'
        },
        {
          key: 'annulee',
          label: 'Annulées'
        },
        {
          key: 'facture_non_valide',
          label: 'Factures non validées'
        },
        {
          key: 'nombre_facture_total',
          label: 'Nombre des factures'
        },
        {
          key: 'somme_total',
          label: 'Montant total'
        }
      ],
      tableSupportFields: [
        {
          key: 'name',
          label: 'Installateur'
        },
        {
          key: 'paye',
          label: 'Payées'
        },
        {
          key: 'paye_montant',
          label: 'Montant Payées'
        },
        {
          key: 'a_paye',
          label: 'À payer'
        },
        {
          key: 'a_paye_montant',
          label: 'Montant À payer'
        },
        {
          key: 'annulee',
          label: 'Annulées'
        },
        {
          key: 'facture_non_valide',
          label: 'Factures non validées'
        },
        {
          key: 'nombre_facture_total',
          label: 'Nombre des factures'
        },
        {
          key: 'somme_total',
          label: 'Montant total'
        }
      ],
      tableDepartementItems: [],
      chartDepartementNbrDossierSeries: [],
      chartDepartementNbrDossierSeriesSvg: [],
      seriesChartMontantDepotSvg: [],
      chartDepartementMontantSeries: [],
      tableDepartementFields: [
        {
          key: 'departement',
          label: 'Département'
        },
        {
          key: 'depot_stockage',
          label: 'Dépot de stockage'
        },
        {
          key: 'attent',
          label: 'Dossier en attente'
        },
        {
          key: 'paye',
          label: 'Dossier payé'
        },
        {
          key: 'total',
          label: 'Total de doosier'
        },
        {
          key: 'montant_final_paye',
          label: 'Montant minimal payé'
        },
        {
          key: 'montant_final_attent',
          label: 'Montant minimal en attente'
        },
        {
          key: 'montant_final',
          label: 'Montant minimal'
        }
      ],
      userRole: [
        'responsable_cactus',
        'responsable_web_abondan',
        'responsable_iso_33',
        'responsable_rge',
        'responsable_dnh',
        'responsable_sav_s',
        'responsable_web_abondance',
        'responsable_corpedys',
        'responsable_acteo',
        'responsable_bureau_etude',
        'responsable_mairie',
        'responsable_gse'
        // 'responsable_upcube_technologies'
      ],
      support: null,
      listType: [
        { value: 'NOVA', text: 'NOVAE' },
        { value: 'CACTUS', text: 'CACTUS' },
        { value: 'AXELIUS', text: 'PINKMAN' },
        { value: 'ISO 33', text: 'KYNAN technique' },
        { value: 'CERTILIA', text: 'CERTILIA' },
        { value: 'GE CDG', text: 'GE CDG' },
        { value: 'SAMI-B', text: 'SAMI-B' },
        { value: 'WEB ABONDANCE', text: 'WEB ABONDANCE' },
        { value: 'CORPEDYS', text: 'CORPEDYS' },
        { value: 'ALTEO France', text: 'ALTEO France' },
        { value: 'NOVAE MAIRIE', text: 'NOVAE MAIRIE' },
        { value: 'GSE', text: 'GSE' }
        // { value: 'upcube', text: 'UPCUBE TECHNOLOGIES' }
      ],
      currentState: false,
      currentStateDepot: false,
      currentStateFacture: false,
      FranceDepartement,
      selectedLocations: [],
      depot_stockage: null,
      ariaLabel: null,
      pointedLocation: null,
      focusedLocation: null,
      SelectedLocationName: [],

      leftStyle: 'left: 0px',
      topStyle: ' top : 0px',
      departmentsListe: null,
      hideGlobalBlock: false,
      delay: 0,
      endVal: null,
      optionsMontant: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '€'
      },
      optionsNbr: {
        useEasing: false,
        useGrouping: false,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'getStatsLoading',
      'getStatsPerMonthLoading',
      'getStatsPerFilialeLoading',
      'getStatsDataPerMonth',
      'getStatsData',
      'getStatsDataPerFiliale',
      'getStatsTotalProject',
      'getStatsNombreFactureTotale',
      'getStatsSommeFactureTotale',
      'getUserData',
      'getAllDepartements',
      'getStatsDataPerMap',
      'getLoadingPerMap',
      'getErrorDataPerMap',
      'getStatsDataPerMapGlobal'
    ]),
    computedMonths() {
      return this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
    },
    computedChartParMoisNombreSeries() {
      if (this.chartParMoisNombreSeries) {
        return this.chartParMoisNombreSeries;
      }
      return [];
    },
    computedChartParMoisNombreOptions() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          },
          area: {
            fillTo: 'end'
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 2,

          curve: 'smooth'
        },
        xaxis: {
          labels: {
            rotate: 0,
            hideOverlappingLabels: false
          },
          categories: this.chartParMoisNombreCategories,
          title: {
            text: 'Nombre des factures'
          }
        },
        colors: [
          '#4caf50', // green
          '#e58390', // red
          '#8987ae', // purple
          '#f7bb48' // yellow
        ],

        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              if (val < 2) {
                return val + ' facture';
              }
              return val + ' factures';
            }
          }
        }
      };
      return chartOptions;
    },
    computedChartParMoisMontantSeries() {
      if (this.chartParMoisMontantSeries) {
        return this.chartParMoisMontantSeries;
      }
      return [];
    },
    computedChartParMoisMontantOptions() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top', // top, center, bottom,
              orientation: 'horizontal'
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          formatter: val => this.formatNumber(val)
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            hideOverlappingLabels: false,
            rotate: 0
          },
          categories: this.chartParMoisMontantCategories,
          title: {
            text: 'Montant des factures'
          }
        },
        colors: [
          '#4caf50', // green
          '#8987ae', // purple
          '#e58390', // red
          '#f7bb48' // yellow
        ],
        yaxis: {
          labels: {
            formatter: val => this.formatNumber(val)
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: val => this.formatNumber(val)
          }
        }
      };
      return chartOptions;
    },

    computedChartInstallateurNombreSeries() {
      if (this.chartInstallateurNombreSeries) {
        return this.chartInstallateurNombreSeries;
      }
      return [];
    },
    computedChartInstallateurNombreOptions() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 250
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top', // top, center, bottom
              orientation: 'vertical'
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 5,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          formatter: val => val
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            rotate: -25,
            hideOverlappingLabels: false
          },
          categories: this.chartInstallateurNombreCategories
        },
        colors: [
          '#4caf50', // green
          '#8987ae', // purple
          '#e58390', // red
          '#f7bb48' // yellow
        ],
        yaxis: {
          title: {
            text: 'Nombre des factures'
          },
          labels: {
            formatter: val => parseInt(val)
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              if (val < 2) {
                return val + ' facture';
              }
              return val + ' factures';
            }
          }
        }
      };
      return chartOptions;
    },
    computedChartInstallateurMontantSeries() {
      if (this.chartInstallateurMontantSeries) {
        return this.chartInstallateurMontantSeries;
      }
      return [];
    },
    computedChartInstallateurMontantOptions() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 250
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top', // top, center, bottom,
              orientation: 'vertical'
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 5,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          formatter: val => this.formatNumber(val)
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            hideOverlappingLabels: false,
            rotate: -25
          },
          categories: this.chartInstallateurMontantCategories
        },
        colors: [
          '#4caf50', // green
          '#8987ae', // purple
          '#e58390', // red
          '#f7bb48' // yellow
        ],
        yaxis: {
          title: {
            text: 'Montant des factures'
          },
          labels: {
            formatter: val => this.formatNumber(val)
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: val => this.formatNumber(val)
          }
        }
      };
      return chartOptions;
    },
    computedChartFilialeSeries() {
      if (this.chartFilialeSeries) {
        return this.chartFilialeSeries;
      }
      return [];
    },
    computedChartFilialeMontantSeries() {
      if (this.chartFilialeMontantSeries) {
        return this.chartFilialeMontantSeries;
      }
      return [];
    },
    computedChartFilialeOptions: function() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top', // top, center, bottom,
              orientation: 'horizontal'
            }
          }
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            hideOverlappingLabels: false,
            rotate: -25
          },
          categories: this.chartFilialeCategories
        },
        colors: [
          '#4caf50', // green
          '#8987ae', // purple
          '#e58390', // red
          '#f7bb48' // yellow
        ],
        yaxis: {
          title: {
            text: 'Nombre des factures'
          },
          labels: {
            formatter: val => parseInt(val)
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              if (val < 2) {
                return val + ' facture';
              }
              return val + ' factures';
            }
          }
        }
      };
      return chartOptions;
    },
    computedChartFilialeMontantOptions: function() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '100%',
            dataLabels: {
              position: 'top', // top, center, bottom,
              orientation: 'vertical',
              offsetY: 5,
              offset: 10,
              minAngleToShowLabel: 10
            }
          }
        },
        dataLabels: {
          enabled: false,

          offsetY: 5,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          formatter: val => this.formatNumber(val)
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            hideOverlappingLabels: false,
            rotate: -25
          },
          categories: this.chartFilialeCategories
        },
        colors: [
          '#4caf50', // green
          '#8987ae', // purple
          '#e58390', // red
          '#f7bb48' // yellow
        ],
        yaxis: {
          title: {
            text: 'Montant des factures'
          },
          labels: {
            formatter: val => this.formatNumber(val)
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: val => this.formatNumber(val)
          }
        }
      };
      return chartOptions;
    },
    computedChartDepartementNbrDossierSeries() {
      if (this.chartDepartementNbrDossierSeries) {
        return this.chartDepartementNbrDossierSeries;
      }
      return [];
    },
    computedseriesChartMontantDepot() {
      const seriesChartMontantDepot = [];

      if (
        this.getStatsDataPerMap.length &&
        !isNaN(this.getStatsDataPerMapGlobal.montant_final_paye_int) &&
        !isNaN(this.getStatsDataPerMapGlobal.montant_final_en_attend_int)
      ) {
        seriesChartMontantDepot.push(
          parseInt(this.getStatsDataPerMapGlobal.montant_final_paye_int),
          parseInt(this.getStatsDataPerMapGlobal.montant_final_en_attend_int)
        );
      }

      return seriesChartMontantDepot ? seriesChartMontantDepot : [];
    },
    computedseriesChartDepot() {
      const seriesChartDepot = [];

      if (
        this.getStatsDataPerMap.length &&
        !isNaN(this.getStatsDataPerMapGlobal.total_paye) &&
        !isNaN(this.getStatsDataPerMapGlobal.total_en_attent)
      ) {
        seriesChartDepot.push(
          parseInt(this.getStatsDataPerMapGlobal.total_paye),
          parseInt(this.getStatsDataPerMapGlobal.total_en_attent)
        );
      }
      return seriesChartDepot ? seriesChartDepot : [];
    },
    computedseriesChartDepotSVG() {
      if (this.chartDepartementNbrDossierSeriesSvg) {
        return this.chartDepartementNbrDossierSeriesSvg;
      }
      return [];
    },
    computedseriesChartMontantDepotSvg() {
      if (this.seriesChartMontantDepotSvg) {
        return this.seriesChartMontantDepotSvg;
      }
      return [];
    },
    computedChartDepartementMontantSeries() {
      if (this.chartDepartementMontantSeries) {
        return this.chartDepartementMontantSeries;
      }
      return [];
    },
    computedChartDepartementNbrDossierOptions: function() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '100%',
            dataLabels: {
              position: 'top', // top, center, bottom,
              orientation: 'horizontal'
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            hideOverlappingLabels: false,
            rotate: -25
          },
          title: {
            text: 'Nombre des dossiers par département'
          },
          categories: this.chartParMapCategories
        },
        colors: [
          '#4caf50', // green
          '#8987ae', // purple
          '#e58390', // red
          '#f7bb48' // yellow
        ],

        fill: {
          opacity: 1
        }
      };
      return chartOptions;
    },
    computedChartMontantDepotOptions: function() {
      const chartOptions = {
        chart: {
          type: 'dount',
          width: 250,
          height: 250,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: false,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10
            },
            donut: {
              size: '65%',
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                  formatter: function(val) {
                    return val;
                  }
                },
                value: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: val => this.formatNumber(val)
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Montant',
                  fontSize: '18px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: function(w) {
                    return (
                      w.globals.seriesTotals.reduce((a, b) => {
                        return (a + b).toLocaleString();
                      }) + ' €'
                    );
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val + '%';
          }
        },
        labels: ['Payé', 'En attente de payement']
      };
      return chartOptions;
    },
    computedChartDepotOptions: function() {
      const chartOptions = {
        chart: {
          type: 'dount',
          width: 250,
          height: 250,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: false,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10
            },
            donut: {
              size: '65%',
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                  formatter: function(val) {
                    return val;
                  }
                },
                value: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: function(val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Dossier',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: function(w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val + '%';
          }
        },
        labels: ['Payé', 'En attente de payement']
      };
      return chartOptions;
    },
    computedChartDepartementMontantOptions: function() {
      const chartOptions = {
        chart: {
          type: 'bar',
          height: 550
        },

        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '100%',
            dataLabels: {
              position: 'top', // top, center, bottom,
              orientation: 'horizontal'
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            hideOverlappingLabels: false,
            rotate: -25
          },
          title: {
            text: 'Montant par départements'
          },
          categories: this.chartParMapCategories
        },
        colors: [
          '#4caf50', // green
          '#8987ae', // purple
          '#e58390', // red
          '#f7bb48' // yellow
        ],

        fill: {
          opacity: 1
        }
      };
      return chartOptions;
    },

    computedRoleSupport() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.userRole.includes(this.getUserData.role)
      ) {
        return false;
      }
      return true;
    },
    computedRoleFiliale() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_filiale'
      );
    },
    computedSupport() {
      switch (this.support) {
        case 'NOVA':
          return 'NOVAE';
        case 'AXELIUS':
          return 'WEB ABONDAN';
        case 'ISO 33':
          return 'ISO 33';
        case 'CERTILIA':
          return 'RGE';
        case 'GE CDG':
          return 'DNH';
        case 'SAMI-B':
          return 'SAV-S';
        case 'WEB ABONDANCE':
          return 'WEB ABONDANCE';
        case 'CORPEDYS':
          return 'CORPEDYS';
        case 'ALTEO France':
          return 'ACTEO';
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'KYNAN - ISO 33 - ISAUDIT';
        case 'NOVAE MAIRIE':
          return 'NOVAE MAIRIE';
        case 'GSE':
          return 'GSE';
        // case 'UPCUBE TECHNOLOGIES':
        //   return 'upcube';
        default:
          return this.support;
      }
    },
    computedListType() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_novae'
      ) {
        return this.listType.filter(
          item => item.value == 'NOVA' || item.value == 'NOVAE MAIRIE'
        );
      }
      return [{ value: null, text: 'Tous les supports' }, ...this.listType];
    },
    getAllFranceDepartement() {
      return ['Tous les dépots', ...this.getAllDepartements];
    }
  },
  methods: {
    ...mapActions([
      'fetchStats',
      'fetchStatsPerMonth',
      'fetchStatsPerFiliale',
      'featchDepotForYearTH',
      'fetchMonthForYearTH',
      'fetchSemaineValiderTh',
      'fetchAllDepartements',
      'fetchStatsParMap',
      'fetchStatsParMapGlobal'
    ]),
    onYearChange() {
      this.week = null;
      this.month = null;
      this.depot = null;
      this.filterOption = true;
      if (!this.computedRoleFiliale) {
        this.filiale = null;
      }

      // this.fetchMonthForYearTH(this.year);
      // this.featchDepotForYearTH(this.year);
      // this.loadStats();
      // this.loadStatsPerMonth();
      // this.loadStatsPerFiliale();

      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      // this.setLocalStorageStats();
    },
    onMonthChange() {
      this.depot = null;
      this.week = null;
      this.filterOption = true;
      if (!this.computedRoleFiliale) {
        this.filiale = null;
      }
      // this.loadStats();
      // this.loadStatsPerMonth();
      // this.loadStatsPerFiliale();
      // //this.handleGetDepartement();
      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      // this.setLocalStorageStats();
    },
    onWeekChange() {
      this.depot = null;
      this.filterOption = true;
      if (!this.computedRoleFiliale) {
        this.filiale = null;
      }
      // this.loadStats();
      // this.loadStatsPerMonth();
      // this.loadStatsPerFiliale();
      // //this.handleGetDepartement();
      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      // this.setLocalStorageStats();
    },
    onFilialeChange() {
      this.depot = null;
      // this.filterOption = true;
      // this.loadStats();
      // this.loadStatsPerMonth();
      // this.loadStatsPerFiliale();
      // //this.handleGetDepartement();
      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      // this.setLocalStorageStats();
    },
    onSupportChange() {
      // this.loadStats();
      // this.loadStatsPerMonth();
      // this.loadStatsPerFiliale();
      // this.setLocalStorageStats();
    },
    onDepotChange() {
      this.filterOption = true;
      // this.loadStats();
      // this.loadStatsPerMonth();
      // this.loadStatsPerFiliale();
      // //this.handleGetDepartement();
      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      // this.setLocalStorageStats();
    },
    formatNumber(number) {
      if (!number) {
        return '0 €';
      }
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }).format(number);
    },
    async loadStats() {
      await this.fetchStats({
        year: this.year,
        month: this.month,
        week: this.week,
        filiale: this.filiale,
        depot: this.depot,
        support: this.computedSupport
      });
      const seriesAnnule = [];
      const seriesAPayer = [];
      const seriesNonValide = [];
      const seriesPayer = [];
      const seriesMontantAnnule = [];
      const seriesMontantAPayer = [];
      const seriesMontantNonValide = [];
      const seriesMontantPayer = [];

      const categories = [];

      let totalPayee = 0;
      let totalAPayer = 0;
      let totalAnnulee = 0;
      let totalNonValide = 0;
      let totalFactureTotal = 0;
      let totalMontantTotal = 0;
      let totalPayeeMontant = 0;
      let totalaPayeeMontant = 0;

      this.tableSupportItems = this.getStatsData.map(item => {
        categories.push(item.name);
        seriesAnnule.push(item.annulee?.nombre_facture || 0);
        seriesAPayer.push(item.a_paye?.nombre_facture || 0);
        seriesNonValide.push(item.facture_non_valide?.nombre_facture || 0);
        seriesPayer.push(item.paye?.nombre_facture || 0);
        seriesMontantAnnule.push(item.annulee?.montant || 0);
        seriesMontantAPayer.push(item.a_paye?.montant || 0);
        seriesMontantNonValide.push(item.facture_non_valide?.montant || 0);
        seriesMontantPayer.push(item.paye?.montant || 0);

        totalPayee += item.paye.nombre_facture;
        totalPayeeMontant += parseFloat(item.paye.montant.replace(/\s/g, ''));
        totalaPayeeMontant += parseFloat(
          item.a_paye.montant.replace(/\s/g, '')
        );
        totalAPayer += item.a_paye.nombre_facture;
        totalAnnulee += item.annulee.nombre_facture;
        totalNonValide += item.facture_non_valide.nombre_facture;
        totalFactureTotal += item.nombre_facture_total;
        totalMontantTotal += parseFloat(item.somme_total.replace(/\s/g, ''));

        return {
          name: item.name,
          paye: item.paye.nombre_facture,
          paye_montant: this.formatNumber(item.paye.montant),
          a_paye: item.a_paye.nombre_facture,
          a_paye_montant: this.formatNumber(item.a_paye.montant),
          annulee: item.annulee.nombre_facture,
          facture_non_valide: item.facture_non_valide.nombre_facture,
          nombre_facture_total: item.nombre_facture_total,
          somme_total: this.formatNumber(item.somme_total.replace(/\s/g, ''))
        };
      });

      this.tableSupportItems.push({
        name: 'TOTAL',
        paye: totalPayee,
        paye_montant: this.formatNumber(totalPayeeMontant),
        a_paye: totalAPayer,
        a_paye_montant: this.formatNumber(totalaPayeeMontant),
        annulee: totalAnnulee,
        facture_non_valide: totalNonValide,
        nombre_facture_total: totalFactureTotal,
        somme_total: this.formatNumber(totalMontantTotal),
        _rowVariant: 'dark'
      });
      this.chartInstallateurNombreSeries = [
        {
          name: 'Payées',
          data: seriesPayer
        },
        {
          name: 'À payer',
          data: seriesAPayer
        },
        {
          name: 'Annulées',
          data: seriesAnnule
        },
        {
          name: 'Factures non validées',
          data: seriesNonValide
        }
      ];

      this.chartInstallateurMontantSeries = [
        {
          name: 'Payées',
          data: seriesMontantPayer
        },
        {
          name: 'À payer',
          data: seriesMontantAPayer
        },
        {
          name: 'Annulées',
          data: seriesMontantAnnule
        },
        {
          name: 'Factures non validées',
          data: seriesMontantNonValide
        }
      ];

      this.chartInstallateurNombreCategories = categories;
      this.chartInstallateurMontantCategories = categories;
    },
    async loadStatsPerFiliale() {
      await this.fetchStatsPerFiliale({
        year: this.year,
        month: this.month,
        week: this.week,
        filiale: this.filiale,
        depot: this.depot,
        support: this.computedSupport
      });
      const seriesAnnule = [];
      const seriesAPayer = [];
      const seriesNonValide = [];
      const seriesPayer = [];
      const seriesMontantAnnule = [];
      const seriesMontantAPayer = [];
      const seriesMontantNonValide = [];
      const seriesMontantPayer = [];

      const categories = [];

      let totalPayee = 0;
      let totalAPayer = 0;
      let totalAnnulee = 0;
      let totalNonValide = 0;
      let totalFactureTotal = 0;
      let totalMontantTotal = 0;
      let totalPayeeMontant = 0;
      let totalaPayeeMontant = 0;

      this.tableFilialeItems = this.getStatsDataPerFiliale.map(item => {
        categories.push(item.name);
        seriesAnnule.push(item.annulee?.nombre_facture || 0);
        seriesAPayer.push(item.a_paye?.nombre_facture || 0);
        seriesNonValide.push(item.facture_non_valide?.nombre_facture || 0);
        seriesPayer.push(item.paye?.nombre_facture || 0);

        seriesMontantAnnule.push(item.annulee?.montant || 0);
        seriesMontantAPayer.push(item.a_paye?.montant || 0);
        seriesMontantNonValide.push(item.facture_non_valide?.montant || 0);
        seriesMontantPayer.push(item.paye?.montant || 0);

        totalPayee += item.paye.nombre_facture;
        totalPayeeMontant += parseFloat(item.paye.montant);
        totalaPayeeMontant += parseFloat(item.a_paye.montant);
        totalAPayer += item.a_paye.nombre_facture;
        totalAnnulee += item.annulee.nombre_facture;
        totalNonValide += item.facture_non_valide.nombre_facture;
        totalFactureTotal += item.nombre_facture_total;
        totalMontantTotal += parseFloat(item.somme_total);

        return {
          name: item.name,
          paye: item.paye.nombre_facture,
          paye_montant: this.formatNumber(item.paye.montant),
          a_paye: item.a_paye.nombre_facture,
          a_paye_montant: this.formatNumber(item.a_paye.montant),
          annulee: item.annulee.nombre_facture,
          facture_non_valide: item.facture_non_valide.nombre_facture,
          nombre_facture_total: item.nombre_facture_total,
          somme_total: this.formatNumber(item.somme_total)
        };
      });
      this.tableFilialeItems.push({
        name: 'TOTAL',
        paye: totalPayee,
        paye_montant: this.formatNumber(totalPayeeMontant),
        a_paye: totalAPayer,
        a_paye_montant: this.formatNumber(totalaPayeeMontant),
        annulee: totalAnnulee,
        facture_non_valide: totalNonValide,
        nombre_facture_total: totalFactureTotal,
        somme_total: this.formatNumber(totalMontantTotal),
        _rowVariant: 'dark'
      });

      this.chartFilialeSeries = [
        {
          name: 'Payées',
          data: seriesPayer
        },
        {
          name: 'À payer',
          data: seriesAPayer
        },
        {
          name: 'Annulées',
          data: seriesAnnule
        },
        {
          name: 'Factures non validées',
          data: seriesNonValide
        }
      ];

      this.chartFilialeMontantSeries = [
        {
          name: 'Payées',
          data: seriesMontantPayer
        },
        {
          name: 'À payer',
          data: seriesMontantAPayer
        },
        {
          name: 'Annulées',
          data: seriesMontantAnnule
        },
        {
          name: 'Factures non validées',
          data: seriesMontantNonValide
        }
      ];

      this.chartFilialeCategories = categories;
    },
    async loadStatsPerMonth() {
      await this.fetchStatsPerMonth({
        year: this.year,
        month: this.month,
        week: this.week,
        filiale: this.filiale,
        depot: this.depot,
        support: this.computedSupport
      });
      const seriesNombre = [];
      const seriesMontant = [];

      const categories = [];

      this.getStatsDataPerMonth.forEach(item => {
        categories.push(item.name);
        seriesNombre.push(item.total || 0);
        seriesMontant.push(item.somme || 0);
      });

      this.chartParMoisNombreSeries = [
        {
          name: 'Nombre des factures',
          data: seriesNombre
        }
      ];

      this.chartParMoisMontantSeries = [
        {
          name: 'Montant des factures',
          data: seriesMontant
        }
      ];

      this.chartParMoisNombreCategories = categories;
      this.chartParMoisMontantCategories = categories;
    },
    checkRole() {
      if (this.getUserData && this.getUserData.role) {
        switch (this.getUserData.role) {
          case 'responsable_novae':
            this.support = 'NOVA';
            break;
          case 'responsable_cactus':
            this.support = 'CACTUS';
            break;
          case 'responsable_web_abondan':
            this.support = 'AXELIUS';
            break;
          case 'responsable_iso_33':
            this.support = 'ISO 33';
            break;
          case 'responsable_rge':
            this.support = 'CERTILIA';
            break;
          case 'responsable_dnh':
            this.support = 'GE CDG';
            break;
          case 'responsable_sav_s':
            this.support = 'SAMI-B';
            break;
          case 'responsable_web_abondance':
            this.support = 'WEB ABONDANCE';
            break;
          case 'responsable_corpedys':
            this.support = 'CORPEDYS';
            break;
          case 'responsable_acteo':
            this.support = 'ALTEO France';
            break;
          case 'responsable_bureau_etude':
            this.support = 'ISO 33';
            break;
          case 'responsable_mairie':
            this.support = 'NOVAE MAIRIE';
            break;
          case 'responsable_gse':
            this.support = 'GSE';
            break;
          // case 'responsable_upcube_technologies':
          //   this.support = 'upcube';
          //   break;
          // case 'responsable_filiale':
          //   this.filiale = this.getUserData.filiale_name;
        }
      }
    },

    pointLocation(event) {
      this.pointedLocation = event.target.ariaLabel + '-' + event.target.id;
      this.leftStyle = 'left : ' + (event.clientX + 50) + 'px';
      this.topStyle = 'top : ' + event.clientY + 'px';
    },
    unpointLocation(event) {
      this.pointedLocation = null;
    },
    focusLocation(event) {
      this.selectedLocations = [];

      this.departmentsListe = null;
      this.focusedLocation = event.target.ariaLabel;

      this.hideGlobalBlock = true;
    },
    blurLocation(event) {
      this.focusedLocation = null;
    },
    getSelectedLocationName(map, locationId) {
      return (
        locationId &&
        map.locations.find(location => location.id === locationId).name
      );
    },

    async handleGetAllDataGlobal() {
      var params = new FormData();
      // this.selectedLocations = [];
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.depot_stockage !== 'Tous les dépots') {
        params.append('depot_stockage', this.depot_stockage);
      }

      const response = await this.fetchStatsParMapGlobal(params);

      if (response.succes) {
        // for (let i = 0; i < this.$el.querySelectorAll('path').length; i++) {
        //   // Clean location
        //   this.$el.querySelectorAll('path')[i].removeAttribute('aria-checked');
        //   for (let j = 0; j < this.getStatsDataPerMap.length; j++) {
        //     if (
        //       this.$el.querySelectorAll('path')[i].id ==
        //         this.getStatsDataPerMap[j].departement &&
        //       !this.$el
        //         .querySelectorAll('path')
        //         [i].getAttribute('aria-checked') &&
        //       this.$el
        //         .querySelectorAll('path')
        //         [i].getAttribute('aria-checked') !== 'true'
        //     ) {
        //       // Add location
        //       this.$el
        //         .querySelectorAll('path')
        //         [i].setAttribute('aria-checked', true);
        //     }
        //   }
        // }
      }
    },
    async handleGetDepartement() {
      var params = new FormData();
      this.selectedLocations = [];
      this.chartDepartementNbrDossierSeriesSvg = [];
      this.seriesChartMontantDepotSvg = [];
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.depot_stockage !== 'Tous les dépots') {
        params.append('depot_stockage', this.depot_stockage);
      }

      const response = await this.fetchStatsParMap(params);

      if (response.succes && this.depot_stockage) {
        for (let i = 0; i < this.$el.querySelectorAll('path').length; i++) {
          // Clean location
          this.$el.querySelectorAll('path')[i].removeAttribute('aria-checked');

          for (let j = 0; j < this.getStatsDataPerMap.length; j++) {
            if (
              this.$el.querySelectorAll('path')[i].id ==
                this.getStatsDataPerMap[j].departement &&
              !this.$el
                .querySelectorAll('path')
                [i].getAttribute('aria-checked') &&
              this.$el
                .querySelectorAll('path')
                [i].getAttribute('aria-checked') !== 'true'
            ) {
              // Add location

              this.$el
                .querySelectorAll('path')
                [i].setAttribute('aria-checked', true);
            }
          }
        }
      }

      const seriesDossierPayer = [];
      const seriesDossierAttent = [];
      const seriesDepartementAttent = [];
      const seriesDepartementPayer = [];
      const categories = [];
      this.getStatsDataPerMap.forEach(item => {
        categories.push(
          this.getSelectedLocationName(FranceDepartement, item.departement)
        );
        seriesDossierPayer.push(item.paye);
        seriesDossierAttent.push(item.attent);
        seriesDepartementAttent.push(
          item.montant_final_attent.replace(/\s/g, '')
        );
        seriesDepartementPayer.push(item.montant_final_paye.replace(/\s/g, ''));
      });

      this.tableDepartementItems = this.getStatsDataPerMap.map(item => {
        return {
          departement: item.departement,
          depot_stockage: item.depot_stockage,
          paye: item.paye,
          attent: item.attent,
          total: item.total,
          montant_final_attent: item.montant_final_attent + ' €',
          montant_final_paye: item.montant_final_paye + ' €',
          montant_final: item.montant_final + ' €'
        };
      });

      this.chartParMapCategories = categories;
    },
    async handleGetDepartementSVG() {
      //this.departmentsListe = null;
      if (!this.filterOption) this.depot_stockage = 'Tous les dépots';
      var params = new FormData();
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.selectedLocations.length) {
        params.append(
          'departement[0]',
          parseInt(this.selectedLocations[this.selectedLocations.length - 1])
        );
        //this.selectedLocations = [];
      } else if (
        this.depot_stockage !== 'Tous les dépots' &&
        this.filterOption
      ) {
        params.append('depot_stockage', this.depot_stockage);
      }

      const response = await this.fetchStatsParMap(params);

      if (response.succes) {
        for (let i = 0; i < this.$el.querySelectorAll('path').length; i++) {
          // Clean location
          this.$el.querySelectorAll('path')[i].removeAttribute('aria-checked');

          if (
            this.$el.querySelectorAll('path')[i].id ==
              this.selectedLocations[this.selectedLocations.length - 1] &&
            !this.$el
              .querySelectorAll('path')
              [i].getAttribute('aria-checked') &&
            this.$el
              .querySelectorAll('path')
              [i].getAttribute('aria-checked') !== 'true'
          ) {
            // Add location

            this.$el
              .querySelectorAll('path')
              [i].setAttribute('aria-checked', true);
          }
        }
        this.handleGetAllDataGlobal();
      }

      const seriesDossierPayer = [];
      const seriesDossierAttent = [];
      const seriesDepartementAttent = [];
      const seriesDepartementPayer = [];
      const categories = [];
      const seriesChartDepot = [];
      const seriesChartDepotMontant = [];

      this.getStatsDataPerMap.forEach(item => {
        categories.push(
          this.getSelectedLocationName(FranceDepartement, item.departement)
        );
        seriesDossierPayer.push(item.paye);
        seriesDossierAttent.push(item.attent);
        seriesDepartementAttent.push(
          item.montant_final_attent.replace(/\s/g, '')
        );
        seriesDepartementPayer.push(item.montant_final_paye.replace(/\s/g, ''));
      });

      this.tableDepartementItems = this.getStatsDataPerMap.map(item => {
        seriesChartDepot.push(parseInt(item.paye), parseInt(item.attent));
        seriesChartDepotMontant.push(
          parseInt(item.montant_final_paye_int),
          parseInt(item.montant_final_attent_int)
        );

        return {
          departement: item.departement,
          depot_stockage: item.depot_stockage,
          paye: item.paye,
          attent: item.attent,
          total: item.total,
          montant_final_attent: item.montant_final_attent + ' €',
          montant_final_paye: item.montant_final_paye + ' €',
          montant_final: item.montant_final + ' €'
        };
      });

      this.chartDepartementNbrDossierSeriesSvg =
        seriesChartDepot.length == 2 ? seriesChartDepot : [0, 0];

      this.seriesChartMontantDepotSvg =
        seriesChartDepotMontant.length == 2 ? seriesChartDepotMontant : [0, 0];

      this.chartParMapCategories = categories;
    },

    AddLocation(event) {
      this.depot_stockage = event ? event : '';

      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      this.hideGlobalBlock = false;
    },
    updateChart() {
      this.currentState = !this.currentState;
    },
    updateChartFacture() {
      this.currentStateFacture = !this.currentStateFacture;
    },
    updateChartDepot() {
      this.currentStateDepot = !this.currentStateDepot;
    },
    onReadyDossier: function(instance, CountUp) {
      const that = this;
      instance.update(
        that.getStatsTotalProject ? that.getStatsTotalProject : 0
      );
    },
    onReadyNombre: function(instance, CountUp) {
      const that = this;
      instance.update(
        that.getStatsNombreFactureTotale
          ? parseInt(that.getStatsNombreFactureTotale.replace(/\s/g, ''))
          : 0
      );
    },
    onReadyMontant: function(instance, CountUp) {
      const that = this;
      instance.update(
        that.getStatsSommeFactureTotale
          ? parseInt(that.getStatsSommeFactureTotale.replace(/\s/g, ''))
          : 0
      );
    },
    allfetchStatsParMap() {
      var params = new FormData();
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.selectedLocations)
        params.append('departement[0]', parseInt(this.selectedLocations[0]));
      this.fetchStatsParMap(params);
    },
    allfetchStatsParMapGlobal() {
      var params = new FormData();
      this.selectedLocations = [];
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.depot_stockage !== 'Tous') {
        params.append('depot_stockage', this.depot_stockage);
      }
      this.fetchStatsParMapGlobal(params);
    },
    setLocalStorageStats() {
      localStorage.setItem(
        'stats',
        JSON.stringify({
          year: this.year,
          month: this.month,
          week: this.week,
          filiale: this.filiale,
          depot: this.depot,
          support: this.support
        })
      );
    }
  },
  async mounted() {
    this.checkRole();
    const currentYear = moment().year();
    this.year = currentYear;

    if (localStorage.getItem('stats')) {
      this.year = JSON.parse(localStorage.getItem('stats')).year;
      this.month = JSON.parse(localStorage.getItem('stats')).month;
      this.depot = JSON.parse(localStorage.getItem('stats')).depot;
      this.week = JSON.parse(localStorage.getItem('stats')).week;
      this.support = JSON.parse(localStorage.getItem('stats')).support;
      this.filiale = JSON.parse(localStorage.getItem('stats')).filiale;
    } else {
      this.year = currentYear;
      this.setLocalStorageStats();
    }

    for (var i = 2019; i <= currentYear; i++) {
      this.years.push({ value: i, text: i });
    }

    // this.handleGetDepartement();
    // this.allfetchStatsParMapGlobal();
    // this.featchDepotForYearTH(this.year);
    // this.fetchMonthForYearTH(this.year);
    // this.fetchSemaineValiderTh({ year: this.year });
    // this.loadStats();
    // this.loadStatsPerMonth();
    // this.loadStatsPerFiliale();
    // this.fetchAllDepartements();
  }
};
</script>
<style lang="scss">
.select-vue-component-style.dep .multiselect__tags,
.dep .multiselect__tag {
  font-size: 13px;
}
.select-vue-component-style.dep .multiselect__option--highlight {
  background: #9799d64d;
  border-radius: 0;
  color: #333;
}
.select-vue-component-style.dep .multiselect__option--selected {
  border-radius: 0;
  background: #258ecf;
  color: #fff;
}
.select-vue-component-style.dep .multiselect__option {
  min-height: 20px;
  line-height: 9px;
  font-size: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.svg-map {
  width: 100%;
  max-width: 100%;
  height: 100%;
  stroke: #fff;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: fill;
  max-height: 85%;
}
.stats-row-panel {
  display: flex;
}

.svg-map__location {
  fill: rgb(173, 225, 251);
  cursor: pointer;
}
.svg-map__location.disabled {
  fill: gray !important;
  cursor: no-drop;
}
.svg-map__location:focus {
  outline: 0;
}
.svg-map .svg-map__location:hover {
  fill: #0c669f !important;
  outline: 0;
  transition: 0.5s;
}
.svg-map__location[aria-checked='true'] {
  fill: rgb(37, 142, 207) !important;
}
.examples__block__map__tooltip {
  position: fixed;
  width: auto;
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}
.child--1 .custom-table-style .thead-light th,
.child--1 .custom-table-style td {
  font-size: 10px;
  min-width: 90px;
  padding: 5px 4px;
}
.child--100 .custom-table-style .thead-light th,
.child--100 .custom-table-style td {
  font-size: 10px;
  min-width: 138px;
  padding: 5px 4px;
}
.child--100 .custom-table-style {
  width: 1300px;
  margin: 0px auto;
}
</style>
<style scoped lang="scss">
.child--1 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 60%;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 20px;
  margin-right: 10px;

  .chart-title {
    flex-direction: column;
    position: relative;

    padding: 12px 12px !important;
    border-radius: 0 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
    font-size: 16px;
    border-bottom: 4px solid #8d8cb7;
  }
  .btnchart {
    display: flex;
    // justify-content: end;
  }
  .btn {
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 12px;
    will-change: transform;
    color: #fff;
    background-color: #8d8cb7;
    border-color: #8d8cb7;
    padding: 0px 10px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    opacity: 0.8;
  }
}
.child--2 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 38%;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 20px;
  margin-right: 10px;
  .chart-title {
    flex-direction: column;
    position: relative;

    padding: 12px 12px !important;
    border-radius: 0 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
    font-size: 16px;
    border-bottom: 4px solid #8d8cb7;
  }
  .btnchart {
    display: flex;
    // justify-content: end;
  }
  .btn {
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 1rem;
    will-change: transform;
    color: #fff;
    background-color: #8d8cb7;
    border-color: #8d8cb7;
    padding: 0px 10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    opacity: 0.8;
  }
  .chargement {
    position: absolute;
    right: 52px;
    top: 11px;
    opacity: 0.5;
  }
}
.child--100 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 100%;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 20px;

  .chart-title {
    flex-direction: column;
    position: relative;

    padding: 12px 12px !important;
    border-radius: 0 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
    font-size: 16px;
    border-bottom: 4px solid #8d8cb7;
  }
  .btnchart {
    display: flex;
    // justify-content: end;
  }
  .btn {
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 12px;
    will-change: transform;
    color: #fff;
    background-color: #8d8cb7;
    border-color: #8d8cb7;
    padding: 0px 10px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    opacity: 0.8;
  }
  .chargement {
    position: absolute;
    right: 152px;
    top: 11px;
    opacity: 0.5;
  }
}

.child--3 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 800px;
  padding: 25px;
  background-color: #fff;
  border-radius: 3%;
  margin-bottom: 20px;
}

.data-style {
  position: relative;

  .examples__block__info__item {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #5d5d5d;
    ul {
      overflow-y: auto;
      height: calc(90vh - 200px);
    }
    .error-message .error {
      font-size: 13px;
    }
  }
}
.stats-page .stats-wrapper .stats-body .stats-content .maps-form-style {
  background-color: #ffffff;
  border-radius: 25px;
  width: 100%;
  padding: 7px;
  display: flex;
  .maps {
    width: 50%;
    flex: initial;
    min-width: auto;
  }
  .map-chart {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listeselect {
    width: 38%;
    flex: initial;
    min-width: auto;
  }
}
.v_Panel {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 800px;
  padding: 25px;
  background-color: #fff;
  border-radius: 3%;
  margin-bottom: 20px;
  .left {
    float: left;
  }
}
.stats-page .stats-wrapper .stats-body .stats-content {
  background-color: transparent;
}
.body-box-maps {
  display: flex;
  background-color: #f5f4fa;
  height: calc(100vh - 109px);
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 27px;
  .data-style ul::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .data-style ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eae7f6;
    border-radius: 10px;
  }
  .data-style ul::-webkit-scrollbar-thumb {
    background: #b4b3d0;
    border-radius: 7px;
  }
}
.red-title {
  color: #8d8cb7;
  font-size: 15px;
  font-weight: bold;
}
.examples__block__info__item .chart-title {
  background-color: #f5f5ff;
  padding: 7px 23px;
  border-radius: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  list-style: none;
  font-size: 12px;
  display: block !important;
}
.chart-title.global {
  display: block !important;
  font-size: 15px;
  line-height: 28px;
}
.switchDashbord {
  position: absolute;
  right: 13px;
  font-size: 12px;
  font-weight: bold;
  color: #8d8cb7;
}
.table-rapport-style {
  padding: 0px !important;
}
</style>
<style lang="scss">
.stats-page {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .stats-wrapper {
    .stats-filter {
      height: 42px;
      width: 100%;
      background-color: #f5f4fa;
      border-radius: 41px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px 10px;
    }
    .stats-body {
      display: flex;
      background-color: #f5f4fa;
      height: calc(100vh - 109px);
      margin-top: 3px;
      border-radius: 28px;
      position: relative;
      padding: 11px;
      .stats-content::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .stats-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #eae7f6;
        border-radius: 10px;
      }
      .stats-content::-webkit-scrollbar-thumb {
        background: #b4b3d0;
        border-radius: 7px;
      }
      .stats-content {
        background-color: #ffffff;
        border-radius: 25px;
        width: 100%;
        position: relative;
        padding: 7px;
        overflow: auto;

        .stats-content-row {
          display: flex;
          flex-wrap: wrap;
        }
        .stats-content-col {
          flex: 1;
          min-width: 400px;

          &.mini-col {
            margin: 0px 5px 20px 10px;
            box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%);
            -webkit-transition: all 0.3s ease-in-out;
            &:hover {
              -webkit-box-shadow: 0 7px 14px rgb(50 50 93 / 10%),
                0 3px 6px rgb(0 0 0 / 8%);
              box-shadow: 0 7px 14px rgb(50 50 93 / 10%),
                0 3px 6px rgb(0 0 0 / 8%);
              -webkit-transform: translateY(-1px);
              transform: translateY(-2px);
              cursor: pointer;
            }
          }
          &.mini-col:first-child {
            margin-left: 0px;
          }
        }
        .chart-title {
          display: flex;
          background-color: #f5f5ff;
          padding: 7px 23px;
          border-radius: 18px;
          margin-bottom: 10px;
          margin-top: 10px;

          .chargement {
            margin-bottom: -5px;
            margin-left: 10px;
            .spinner-border {
              width: 22px;
              height: 22px;
            }
          }
        }

        .total-stats {
          margin: 0;
          background: #fff;
          border-radius: 0;

          width: 100%;
          padding: 21px 12px;
          display: flex;
          .total-stats-title {
            height: 60px;
            background: transparent;
            color: #626262;
            padding: 10px;
            display: flex;
            justify-content: left;
            align-items: center;
            font-size: 15px;
          }
          .total-stats-value {
            color: black;
            font-size: 35px;
            padding: 10px;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
          }
          .left-stats {
            width: 90%;
          }
          .right-stats {
            display: flex;
            // justify-content: end;
            // align-items: end;
          }
        }
      }
    }
  }
  .bold {
    font-weight: bold;
  }
}
</style>
