import { render, staticRenderFns } from "./StatisticsGeneral.vue?vue&type=template&id=4fa50554&scoped=true&"
import script from "./StatisticsGeneral.vue?vue&type=script&lang=js&"
export * from "./StatisticsGeneral.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsGeneral.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./StatisticsGeneral.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./StatisticsGeneral.vue?vue&type=style&index=2&id=4fa50554&scoped=true&lang=scss&"
import style3 from "./StatisticsGeneral.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa50554",
  null
  
)

export default component.exports